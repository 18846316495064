<template>
  <b-card>
    <b-card-title style="font-size: xx-large; text-align: center;">
      Service Info
    </b-card-title>
    <b-card-text><strong>Name: </strong>{{ serviceData.name }}</b-card-text>
    <b-card-text><strong>Description: </strong>{{ serviceData.description }}</b-card-text>
    <b-card-text><strong>Approved: </strong>{{ serviceData.approved }}</b-card-text>
    <b-card-text><strong>Estimate Money: </strong>{{ serviceData.estimate_money }}</b-card-text>
    <b-card-text><strong>Location: </strong>{{ serviceData.location }}</b-card-text>
    <b-card-text><strong>Days Require: </strong>{{ serviceData.days_require }}</b-card-text>

    <diV>
      <b-card-title style="font-size: xx-large;">
        Request Info
      </b-card-title>
      <b-card-text v-if="serviceData.request">
        <strong>Request Code: </strong>{{ serviceData.request.request_code }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Request Status: </strong>{{ serviceData.request.status }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Source: </strong>{{ serviceData.request.source }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Certificate Name: </strong>{{ serviceData.request.certificate_name.en }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Certificate Name Arabic: </strong>{{ serviceData.request.certificate_name.ar }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Payment Status: </strong>{{ serviceData.request.payment_status }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Final Price: </strong>{{ serviceData.request.final_price }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Location: </strong>{{ serviceData.request.location }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Require Audit: </strong>{{ serviceData.request.require_audit }}
      </b-card-text>
      <b-card-text v-if="serviceData.request">
        <strong>Due Date: </strong>{{ serviceData.request.due_date }}
      </b-card-text>
    </diV>

    <div>
      <b-card-title style="font-size: xx-large;">
        Requester Info
      </b-card-title>
      <b-card-text v-if="serviceData.requester">
        <strong>Name: </strong>{{ serviceData.requester.name }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester">
        <strong>Email: </strong>{{ serviceData.requester.email }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester">
        <strong>Phone: </strong>{{ serviceData.requester.phone }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester">
        <strong>Description: </strong>{{ serviceData.requester.description }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester">
        <strong>Job Description: </strong>{{ serviceData.requester.job_description }}
      </b-card-text>
      <b-card-text v-if="serviceData.requester">
        <strong>Wallet Balance: </strong>{{ serviceData.requester.wallet_balance }}
      </b-card-text>
    </div>

    <b-card-title style="font-size: xx-large;">
      Engineers List
    </b-card-title>
    <div>
      <b-card-text><strong>Files: </strong>
        <ul>
          <li
            v-for="file in serviceFile"
            :key="file.id"
          >
            <a> {{ file.url }} </a>
          </li>
        </ul>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      serviceData: {
        name: '',
        description: '',
        approved: '',
        estimate_money: '',
        location: '',
        days_require: '',
        request: {
          id: '',
          request_code: '',
          status: '',
          source: '',
          certificate_name: {
            ar: '',
            en: '',
          },
          payment_status: '',
          final_price: '',
          location: '',
          require_audit: '',
          due_date: '',
        },
        requester: {
          name: '',
          email: '',
          phone: '',
          description: '',
          job_description: '',
          joined_at: '',
          wallet_balance: '',
        },
      },
      serviceFile: [],
    }
  },
  mounted() {
    this.showService()
  },
  methods: {
    showService() {
      if (this.$route.params.id) {
        axios
          .get(`/service/${this.$route.params.id}`)
          .then(res => {
            this.serviceData = decryptData(res.data.payload).service
            this.serviceFile = decryptData(res.data.payload).service.files
          })
      }
    },
  },
}
</script>

<style scoped></style>
